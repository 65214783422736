import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { httpInterceptor } from './core/interceptors/http.interceptor';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ToastService } from './shared/service/toast.service';
import { MessageService } from 'primeng/api';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PersonPipe } from './pipe/person.pipe';
import { TipoVinculoPipe } from './pipe/tipo-vinculo.pipe';
import { CurrentPipe } from './pipe/current.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    PersonPipe,
    TipoVinculoPipe,
    CurrentPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    AppRoutingModule,
    ToastModule,
    DialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports:[
    ToastModule,
    DialogModule
  ],
  bootstrap: [AppComponent],
  providers: [
    ToastService,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi:true},
  ],
})

export class AppModule {}
