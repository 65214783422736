import { Injectable, NgModule } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpResponse, HttpErrorResponse, HttpResponseBase, HttpHeaderResponse } from '@angular/common/http';

import { Observable, catchError, finalize, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ToastService } from '../../shared/service/toast.service';
import { LoadingService } from '../../shared/service/loading.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class httpInterceptor implements HttpInterceptor {
  public token:any
  constructor(
    private message: ToastService,
    private loading: LoadingService,
    // private oauthService: OAuthService,
  ) {
    // console.log('interceptado')
    this.token = sessionStorage.getItem('id_token');  
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Inicia Componente de loading no inicio da consulta
    this.loading.showLoading(true)
    // Adiciona o servidor da consulta de acordo com o ambiente(environment)
    req = this.setUrlServer(req);
    // Adiciona paramentros no header da consulta
    req = this.setHeaders(req);
    // console.log(req)
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {

        if (event instanceof HttpResponse) {

          const request = event.body?.meta;
          if (request) { // Condição "ADAPTADA" para o retorno do status da consulta da forma desenvolvida pelo Backend

           // if ([404].includes(request?.codigo)) {
              //this.message.showError('APi nao encontrada')
              // this.oauthService.logOut();
           // }
            if ([401, 403, 422].includes(request?.codigo)) {
              //this.geraMensagemListaObjetos(request.mensagem)
              console.log('erro 2')
              // this.oauthService.logOut();
            }

          } else {  // Retorno padrão do status de uma requisição Http
            // console.log()
          }

          // if (event.body?.meta?.codigo == 401) {
          // } else {
          //   // throw new HttpErrorResponse({
          //   //     error: event.
          //   // });
          // }
        }
        return event;
      }),

      catchError((error) => {
        if ([404].includes(error.status)) {
          this.message.showError('APi nao encontrada');
          //this.service.logout()
        }
        if ([401, 403].includes(error.status)) {
          this.message.showError('Nao autorizado');
          //this.service.logout()
        }
        const e = error.error.message || error.statusText;
        console.error(e)
        return throwError(() => error)
      }),

      finalize(() => {

        //finaliza Componente de loading da consulta
        this.loading.showLoading(false);

      }),
    );
  }

  private geraMensagemListaObjetos(obj: any) {

    switch (typeof obj) {
      case "string":
        this.message.showWarn(obj)
        break;

      case "object":
        Object.values(obj).forEach((element: any) => {
          this.message.showWarn(element[0])
        })
        break;

      default: break;
    }

  }

  // se o serviço chamar uma url com http é ignorado o environment.ADDRESS adicionado ao inicio da mesma
  private setUrlServer(request: HttpRequest<any>) {

    if ( request.method == 'POST' && request.url.endsWith('ssc-idp-frontend/rest/oidc/token')) {
      if (typeof request.serializeBody() == 'string') {

        // this.deserializedData = this.deserializeQueryString(
        //   request.serializeBody()
        // );

        // if (this.deserializedData?.grant_type == 'refresh_token') {
        //   this.deserializedData = this.deserializeQueryString(
        //     request.serializeBody()
        //   );

        //   this.modifiedRequest = request.clone({
        //     setHeaders: {
        //       Authorization: environment.authorization_ssc,
        //     },
        //     url: environment.refresh_token_endpoint,
        //   });

        //   // Passa a requisição modificada para o próximo manipulador
        //   // return next.handle(this.modifiedRequest);
        // } else {
        //   this.deserializedData = this.deserializeQueryString(
        //     request.serializeBody()
        //   );
        console.log(environment.authorization_ssc)
          return request.clone({
         
            setHeaders: {
              Authorization: environment.authorization_ssc,
            },
          })

          // return request.clone({
          //     headers: request.headers.set('Authorization', `Bearer ${environment.authorization_ssc}`);
          // });

          // Passa a requisição modificada para o próximo manipulador
          // return next.handle(this.modifiedRequest);
        // }
      }else{
        return request.clone();
      }
    }
    else if (request.url.indexOf("http") != -1) {
      return request.clone({
        url: request.url
      });
    }
    else {
      let token: any = sessionStorage.getItem('id_token');  
      return request.clone({
        url: environment.service + request.url,
        headers: request.headers.set('Authorization', `Bearer ${token}`)        
       
      });
    }
  }

  // Monta o header das chamadas
  private setHeaders(request: HttpRequest<any>) {
    // console.log(request)
    // let contentType = 'application/json; charset=UTF-8';

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     headers: request.headers.set('Content-Type', contentType)
    //   });
    // }

    if (!request.headers.has('token')) {
      // const usuario = this.authService.storage.get<Usuario>(LocalStorageTypes.Usuario);
      // if (usuario) {
      //   // const uId = usuario?.usuarioEvandoMirraId ? usuario?.usuarioEvandoMirraId : 0;
      //   request = request.clone({
      //     headers: request.headers.set('usuarioId', String(usuario.usuarioId))
      //       .set('token', usuario.token)
      //       .set('usuarioEvandoMirraId', String(uId))
      //     //.set('Authorization', `Bearer ${usuario.token}`);
      //   });
      // }
    }

    return request;
  }
}


@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpInterceptor,
      multi: true
    }
  ]
})
export class Interceptor { }
