import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../service/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent  implements OnInit {

  public visible: boolean = true;

  constructor(public load: LoadingService) { }

  ngOnInit(): void {
    let status = this.load.loading$.subscribe((status: any) => {
      setTimeout(() => {      
        this.visible = (status) ? Boolean(status) : false
      }, 300);    
      // console.log(this.visible);
      return this.visible;
    });
  }
}
