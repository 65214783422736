import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './core/authentication/authentication.service'
import { LocalStorageService } from './shared/service/localstorage/localstorage.service';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BnNgIdleService } from 'bn-ng-idle';
import { ToastService } from './shared/service/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  title = 'Argos';

  constructor(
    private auth: AuthenticationService,
    private localStorage: LocalStorageService,
    private router: Router,
    private oauthService: OAuthService,
    private idleService: BnNgIdleService,
    private message: ToastService,
  ){
    this.auth.conectar();
  }

  ngOnInit() {

    this.idleService.startWatching(3600).subscribe((isUserInactive) => {

      if (isUserInactive) {
        // console.log('Sessão expirada - tempo ocioso');
        this.message.showError('Sessão expirada: Tempo ocioso');
        this.localStorage.clearData();
        this.oauthService.logOut();
        this.idleService.resetTimer();
      }

    });
  }

}
